import React, { Component, Suspense } from 'react';
import './index.css';

import axios from 'axios';
import { config } from '../../../config';

//import react router
import {
    Switch,
    Route,
    BrowserRouter as Router,
    withRouter
} from "react-router-dom";

//component
import Footer from '../../Navigation/Footer/footer';
import Header from '../../Navigation/Header/header';
import About from './AboutPage/about';
import News from './Actualité/index';
import HomePage from './HomePage/index';
import Network from './Network/network';
import Product from './Product';
import NotYet from '../../Navigation/notYetPage';
import Contact from './ContactPage/contact';
import Subscription from './Subscription/Subscription';
import Faq from './Faq/faq';
import Service from './Services/service';
import Offre from './Offres/offre';
import Debit from './Offres/flowDetails/debit';
import Products from './Products';
import AllCategory from './Products/AllCategory/allCategory';
import OffrePack from './OffresPack';
//popup
import Popup from '../../UI/popup/popup';
import Model from '../../UI/popup/model';

//Scroll to top component
import ScrollToTop from '../../ScrollToTop/scrollToTop';



class Particulier extends Component {
    state = {
        productItems: null,
        serviceItems: null,
        offreCategories: null,
        offreItems: null,
        offrePackCat: [],
        offrePack: [],
        modalInfo: [],
        popup: false
    };

    componentDidMount() {
        // let visited = localStorage["alreadyVisited"];
        let visited = true
        if (visited) {
            //do not view Popup
            this.setState({ popup: false });
        } else {
            //this is the first time
            localStorage["alreadyVisited"] = true;
            this.setState({ popup: true });
        }
        axios.get(`${config.url}produits`)
            .then(response => {
                this.setState({ productItems: response.data });
            }).catch(error => console.log(error, "related to product"));

        axios.get(`${config.url}services`)
            .then(response => {
                this.setState({ serviceItems: response.data });
            }).catch(error => console.log(error, "related to service"));

        axios.get(`${config.url}catoffres`)
            .then(response => {
                this.setState({ offreCategories: response.data });
            }).catch(error => console.log(error, "related to offre categories"));

        axios.get(`${config.url}offres`)
            .then(response => {
                this.setState({ offreItems: response.data });
            }).catch(error => console.log(error, "related to offre"));

        axios.get(`${config.url}catoffrespack`)
            .then(response => {
                this.setState({ offrePackCat: response.data });
            }).catch(error => console.log(error, "related to offre pack cat"));
        axios.get(`${config.url}offrespack`)
            .then(response => {
                this.setState({ offrePack: response.data });
            }).catch(error => console.log(error, "related to offre pack"));

        // axios.get(`${config.url}getPopup`)
        // axios.get(`http://bobee.test/api/getPopup`)
        //     .then(response => {
        //         this.setState({ modalInfo: response.data })
        //     }).catch(error => console.log(error, "related to modalInfo"))

    }
    //popup handler
    handlePopupClose = () => {
        this.setState({ popup: false });
    };

    render() {
        let services = this.state.serviceItems ? this.state.serviceItems : [];
        let produits = this.state.productItems ? this.state.productItems : [];
        let categories = this.state.offreCategories ? this.state.offreCategories : [];
        let offers = this.state.offreItems ? this.state.offreItems : [];

        return (
            <Router>
                <ScrollToTop />
                <Popup show={this.state.popup} handleClose={this.handlePopupClose}
                    message="Dans le cadre d’appliquer et s’aligner à la mise à jour de la redevance mensuelle de l’abonnement à la téléphonie fixe procédée par Tunisie Télécom et approuvée par l’INT, 
                une augmentation tarifaire de 1,500 DT (TTC) sera mise en œuvre et ce à partir du 01 septembre 2022, concernera aussi bien les nouveaux et anciens abonnés.
                les frais de raccordement sont gratuits sur toute nouvel abonnement xDSL ayant un débit supérieur ou égal à 20 Mbps."
                />
                {/* <Model show={this.state.popup} handleClose={this.handlePopupClose} 
                titre = {this.state.modalInfo.Titre}
                message={this.state.modalInfo.Description}
                url={this.state.modalInfo.Link}
                /> */}
                {/*Navigation menu*/}
                <div className="sticky-top">
                    <Header
                        offreItems={this.state.offreItems} offreCategories={this.state.offreCategories}
                        serviceItems={this.state.serviceItems} productItems={this.state.productItems}
                        offrePackCat={this.state.offrePackCat}
                    />
                </div>

                {/*Navigation menu*/}

                {/*Content*/}
                <Switch>
                    <Route exact path={["/", "/particulier"]}>
                        <HomePage offerItems={this.state.offreItems} catOffers={this.state.offreCategories}
                            offrePackCat={this.state.offrePackCat} offrePack={this.state.offrePack}
                        />
                    </Route>
                    <Route path='/particulier/actuality' component={News} />
                    <Route path="/particulier/about" component={About} />
                    <Route path="/particulier/NotreRéseau" component={Network} />
                    <Route path="/particulier/contact" component={Contact} />


                    {/* -------------------------------- */}
                    <Route path="/particulier/produits/Category"> <AllCategory products={this.state.productItems} /> </Route>
                    {/* -------------------------------- */}

                    {produits.map((el, id) =>
                        <Route path={`/particulier/produits/${el.Nom.replace(/[()]/g, '')}`} render={(props) =>
                            <Product products={this.state.productItems} productCat={el.id_cat} id={id} {...props} />} />)}


                    <Route path="/particulier/produits"> <Products products={this.state.productItems} /> </Route>


                    {services.map((el, id) =>
                        <Route path={`/particulier/service/${el.Titre.replace(/ /g, '')}`} render={(props) =>
                            <Service services={this.state.serviceItems} id={id} {...props} />} />)}

                    <Route path='/particulier/offre/'>
                        {categories.map((el, id) => {
                            return (
                                <Switch>
                                    {offers.map((offre, idOffre) => {
                                        if (el.id == offre.categorie_offres_id)
                                            return (
                                                <Route path={`/particulier/offre/${offre.nom.replace(/ /g, '')}`} render={(props) =>
                                                    <Offre key={idOffre} idCat={id} idOffre={idOffre} offers={this.state.offreItems} category={this.state.offreCategories} {...props} />} />
                                            );
                                    })}
                                </Switch>
                            );
                        })}
                    </Route>

                    <Route path='/particulier/offre-packagée/'>
                        {this.state.offrePackCat.map((el, id) => {
                            return (
                                <Switch>
                                    {this.state.offrePack.map((offre, idOffre) => {
                                        if (el.id == offre.categorie_offresPackgers_id)
                                            return (
                                                <Route path={[`/particulier/offre-packagée/${el.type.replace(/ /g, '')}/:test`, `/particulier/offre-packagée/${el.type.replace(/ /g, '')}`]} render={(props) =>
                                                    <OffrePack test={props.match.params.test} idPack={el.id} idOffrePack={idOffre} categoryPack={this.state.offrePackCat} offres={this.state.offrePack} {...props} />} />
                                            );
                                    })}
                                </Switch>
                            );
                        })}
                    </Route>
                    {/* <Route path="/particulier/offre/:idCat/:idOffre/debit/:idDebit" render={(props) => 
                    <Debit key={props.match.params.idDebit} services={this.state.serviceItems} {...props}/>} /> */}
                    <Route path="/particulier/abonnement/:type/:Reference" component={Subscription} />
                    <Route path="/particulier/faq" component={Faq} />
                    <Route exact path="/business" component={NotYet} />
                </Switch>
                {/*Content*/}

                {/*Footer*/}
                <div>
                    <Footer services={this.state.serviceItems}
                        offres={this.state.offreItems} offreCategories={this.state.offreCategories}
                        offrePackCat={this.state.offrePackCat} />
                </div>
                {/*Footer*/}
            </Router>
        );
    }
}
export default withRouter(Particulier);