import React, { Component } from 'react';
//css
import './contact.css';
//react-bootstrap
import {
    Row,
    Col,
    Image
} from 'react-bootstrap';
import ContactMap from './Map/map';

//images
const MAP_IMG = require("../../../../asset/image/Particulier/map-img.png");
const ADRESS_LOGO = require("../../../../asset/image/Particulier/contact-adress-logo.png");
const MOBILE_LOGO = require("../../../../asset/image/Particulier/contact-mobile-logo.png");
const MAIL_LOGO = require("../../../../asset/image/Particulier/contact-mail-logo.png");

export default class ContactHeader extends Component {
    render() {
        return (
            <Row className="mx-0 px-0 justify-content-center">
                <Col xl="12" md="12" xs="12" className="mx-0 px-0 mb-xl-5 mb-3 contact-header">
                    <h1 className="contact-title"> CONTACTEZ NOUS </h1>
                </Col>
                <Col xl="5" md="11" xs="12" className="mx-2 mb-4">
                    {/* <Image fluid className="contact-header-img" src={MAP_IMG}/> */}
                    <ContactMap />
                </Col>
                <Col xl="5" md="12" xs="10" className="mx-0 px-0">
                    <Row className="mx-0 px-0 contact-card-box">
                        <Col xl="5" md="3" xs="10" className="mx-2 mb-4 contact-card">
                            <div className="contact-logo-container mx-auto my-4">
                                <Image fluid src={ADRESS_LOGO} className="m-xl-4 m-3" />
                            </div>
                            <div className="mb-5">
                                <p className="contact-card-title"> Adresse </p>
                                <p className="contact-card-text">Résidence Omar, 2ème étage 1073 – Montplaisir, Tunis.  </p>
                            </div>
                        </Col>
                        <Col xl="5" md="3" xs="10" className="mx-2 mb-4 contact-card">
                            <div className="contact-logo-container mx-auto my-4">
                                <Image fluid src={MOBILE_LOGO} className="m-xl-4 m-3" />
                            </div>
                            <div className="mb-5">
                                <p className="contact-card-title"> Téléphone </p>
                                <p className="contact-card-text"> +216 71 100 440 </p>
                            </div>
                        </Col>
                        <Col xl="5" md="3" xs="10" className="mx-2 mb-4 contact-card">
                            <div className="contact-logo-container mx-auto my-4">
                                <Image fluid src={MAIL_LOGO} className="m-xl-4 m-3" />
                            </div>
                            <div className="mb-5">
                                <p className="contact-card-title"> Email </p>
                                <p className="contact-card-text"> contact@bee.net.tn </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}